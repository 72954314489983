import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [2,4,3];

export const dictionary = {
		"/gatewayinfo/homey": [5],
		"/home": [6,[2]],
		"/home/settings": [10,[2]],
		"/home/settings/properties/new": [17,[2]],
		"/home/settings/properties/[id]": [11,[2,4]],
		"/home/settings/properties/[id]/demandresponse": [12,[2,4]],
		"/home/settings/properties/[id]/general": [13,[2,4]],
		"/home/settings/properties/[id]/loadshifting": [14,[2,4]],
		"/home/settings/properties/[id]/meterpoint": [15,[2,4]],
		"/home/settings/properties/[id]/peakshaving": [16,[2,4]],
		"/home/settings/subscription": [18,[2]],
		"/home/settings/user": [19,[2]],
		"/home/[propertyid]": [7,[2,3]],
		"/home/[propertyid]/devices": [8,[2,3]],
		"/home/[propertyid]/devices/[deviceid]": [~9,[2,3]],
		"/login": [20],
		"/logout": [~21],
		"/onboarding": [22],
		"/onboarding/homey": [23],
		"/password/reset": [24],
		"/password/reset/[token]": [~25],
		"/register": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';