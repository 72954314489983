import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
	dsn: 'https://5928ca5193cebb604c6036caa994d58e@o4506274679816192.ingest.sentry.io/4506274682765312',

	environment: dev ? 'development' : 'production',

	// Only trace 25% of transactions on backend in prod, 100% in dev
	tracesSampleRate: dev ? 1 : 0.25,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

// Handle flutter alerts when app is running in a webview
if (window.FLUTTER) {
	// Overskrive alert
	window.alert = function (message) {
		try {
			FLUTTER.postMessage(
				JSON.stringify({
					type: 'alert',
					message: message
				})
			);
		} catch (error) {
			console.error('Error in Flutter alert:', error);
		}
	};
}
